.ge_picker_modal {
    box-sizing: border-box;
    position: absolute;
    padding: 0px;
    /*background-color: var(--ui-component-color);*/
    overflow: hidden;
    box-shadow: var(--modal-shadow);
    user-select: none;
    z-index: var(--z-helpers); /* z-index will be modified by Greensock Draggable when dragging */
}

.ge_picker_modal * {
    box-sizing: border-box;
}

.ge_picker_canvas {
    width: 100%;
    height: 100%;
    cursor: crosshair;
}

.ge_colorpicker_patch {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
}

.ge_colorpicker_hsv-map {
    position: relative;
    height: 200px;
    width: 100%;
    top: 50px;
}

.ge_colorpicker_disc-cover {
    opacity: 0;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: crosshair;
}

.ge_colorpicker_disc-cover::after {
    content: '';
    background-color: transparent;
    position: absolute;
    top: -1px;
    left: -1px;
    width: 202px;
    height: 202px;
    border: 3px solid var(--ui-component-color);
    border-radius: 50%;
    opacity: 1;
    pointer-events: none;
    box-sizing: border-box;
}

.ge_colorpicker_bar-bg,
.ge_colorpicker_bar-white {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 200px;
}

.ge_colorpicker_bar-white {
    background-color: #fff;
}

.ge_colorpicker_disc-cursor {
    position: absolute;
    border: 1px solid #eee;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin: -5px;
    cursor: crosshair;
}

.ge_colorpicker_dark .ge_colorpicker_disc-cursor {
    border-color: #333;
}

/*.ge_colorpicker_no-cursor,
.ge_colorpicker_no-cursor .ge_colorpicker_patch,
.ge_colorpicker_no-cursor .ge_colorpicker_disc-cursor,
.ge_colorpicker_no-cursor .ge_colorpicker_disc-cover {
    cursor: none;
}*/

.ge_colorpicker_bar-luminance {
    position: absolute;
    right: 0;
    top: 0;
}

.ge_colorpicker_bar-cursors {
    position: absolute;
    right: 0;
    width: 25px;
    top: 0;
    height: 200px;
    overflow: hidden;
}
.ge_colorpicker_bar-cursor-left,
.ge_colorpicker_bar-cursor-right {
    position: absolute;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    margin-top: -4px;
}
.ge_colorpicker_bar-cursor-left {
    left: 0;
    border-left: 4px solid #eee;
}
.ge_colorpicker_bar-cursor-right {
    right: 0;
    border-right: 4px solid #eee;
}

.ge_colorpicker_dark .ge_colorpicker_bar-cursor-left {
    border-left-color: #333;
}
.ge_colorpicker_dark .ge_colorpicker_bar-cursor-right {
    border-right-color: #333;
}

.ge_colorpicker_link-button {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: #85CCC4; /*white;*/
    font-family: Helvetica, Arial, sans-serif;;
    font-size: 20px;
    font-weight: 100;
    cursor: pointer;
}

