.ge_assing_marker {
    margin-left: -15px;
	color: var(--ui-highlight-color);
    font-size: 10px;
    /*transform: translate(0,50%);*/
}

.ge_assing_marker_faster {
    color: green;
    background-color: green;
}

.ge_assing_marker_pct {
    position: absolute;
    top: 0px;
    left: -17px;
    height: 100%;
    z-index: -1;
    background-color: #555;
}

.ge_assing_marker_slower {
    background-color: red;
}

.ge_assing_marker:hover {
    color: white;
}

.ge_assing_marker_on {
    margin-left: -15px;
    color: white;
}

