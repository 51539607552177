/* CANVAS */

.ge_canvas_container {
    position: fixed;
    right: 0px;
    margin: 0px;
    padding: 0px;
	z-index: var(--z-shader);
}

.ge_canvas {
    margin: 0px;
    padding: 0px;
	z-index: var(--z-shader);
}

.ge_control {
    margin: 0px;
    padding: 0px;
    position: absolute;
    width: 100%;
    bottom: 16px;

    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s;
    z-index: var(--z-shader);
}

.ge_control_panel {
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 120px;
    background-color: rgba(0,0,0,.5);
    font-size: 18px !important;
    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    transition: opacity .3s;
    z-index: var(--z-shader);
}



.ge_contol li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ge_control_hidden {
    /*display: none;*/
    opacity: 0;
}

.ge_control_element {
	background-color: rgba(0,0,0,0);
	color: var(--ui-component-text-color);
    font-family: Helvetica, Geneva, sans-serif;
    font-weight: 300;
	display: inline;
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ge_control_element_button {
  display: inline-block;
	margin: 0px 8px 0px 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    overflow: hidden;
    outline: none;
    //border: solid 1px rgba(255, 255, 255, 0.8);
    //border-radius: 4px;
    -moz-user-select: -moz-none;
  	-khtml-user-select: none;
  	-webkit-user-select: none;
  	-ms-user-select: none;
  	user-select: none;
}

.ge_control_element_button::selection { background: transparent;color:inherit; }
.ge_control_element_button::-moz-selection { background: transparent;color:inherit; }

.ge_control_element_button:hover {
    color: var(--ui-highlight-color);
}

.ghostdom {
	background: #999;
	opacity: 0.2;

	position: absolute;
	right: 0px;
	margin: 0;
	padding: 0;

	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;

	z-index: var(--z-shader-ghost);
}
