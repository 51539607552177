/* EDITOR */

.CodeMirror {
    font-family: var(--editor-font-family);
    font-size: 14px;
    line-height: 1.4;
    height: auto;
    z-index: var(--z-editor);
}

.CodeMirror-linenumbers {
    padding: 0 8px;
    font-family: Helvetica, Geneva, sans-serif;
}

.CodeMirror-linenumber.CodeMirror-gutter-elt {
    color: #546E7A;
}

/* Slightly more padding around the text content */
.CodeMirror pre {
    padding: 0 8px;
}

.CodeMirror-vscrollbar {
	visibility: hidden;
}

.ge_editor {
    background-color: #272823;
}

.ge_editor-unfocus {
    opacity: .5;
    filter: blur(1px);
    background-color: #272823;
}

.CodeMirror {
    margin-top: 12px;
}
