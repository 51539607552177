.ge_modal {
    box-sizing: border-box;
    position: absolute;
    z-index: var(--z-helpers); /* z-index will be modified by Greensock Draggable when dragging */
    user-select: none;
    box-shadow: var(--modal-shadow);
    font-family: Helvetica, Geneva, sans-serif;
    font-weight: 100;
}

.ge_export_modal {
    overflow: hidden;
    color: var(--ui-component-text-color);
    background-color: var(--ui-base-color);
    /*box-shadow: var(--modal-shadow);*/
    box-shadow: 0 5px 5px 5px rgba(0,0,0,.2),0 5px 0px 0px rgba(0,0,0,.25);
    user-select: none;
    cursor: pointer;
    margin: 0px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    list-style-type: none;
}

.ge_tooltip_modal {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: var(--ui-component-color);
    font-size: 14px;
}

.ge_tooltip_modal a {
    text-decoration: none;
    color: var(--ui-highlight-color);
}

.ge_tooltip_modal p {
    color: var(--ui-subtext-color);
    margin: 6px;
}