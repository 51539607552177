.ge_panel {
    cursor: pointer;
    color: var(--ui-component-text-color);
    background-color: var(--ui-base-color);
    font-family: Helvetica, Geneva, sans-serif;
    font-weight: 100;
    margin: 0px;
    padding-top: 5px;
    z-index: var(--z-menu);
    border-bottom: solid 1px #444444;
}

li.ge_panel_tab {
    cursor: pointer;
    list-style-type: none;
    background-color: var(--ui-base-color);
    color: #777777;
    display: inline-block;
    text-align: center;
    padding: 8px 16px 8px 16px;
    margin-bottom: 8px;
    letter-spacing: 0.05em;
    font-face: monospace;
    font-size: 14px;
}

li.ge_panel_tab_active {
    cursor: pointer;
    //background-color: var(--ui-active-color);
    border-radius: 4px;
    list-style-type: none;
    display: inline-block;
    text-align: center;
    padding: 8px 16px 8px 16px;
    margin-bottom: 8px;
    letter-spacing: 0.05em;
    font-size: 14px;
}

li.ge_panel_tab:hover {
    color: var(--ui-highlight-color);
}

a.ge_panel_tab_close {
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 100;
}
