
.ge-error,
.ge-warning {
    position: relative;
    font-family: var(--editor-font-family);
    padding-left: 3em; /* Theoretically, aligns error text to the first tab stop */
    padding-right: 10px;
}

.ge-error-icon,
.ge-warning-icon {
    position: absolute;
    left: 0;
    /*top: 3px;*/
    width: 3em; /* Centers the icon within the first tab stop */
    text-align: center;
}

.ge-error {
    background: #be1a20;
    color: white;
}

.ge-warning {
    background: #ffcc00;
    color: black;
}